<template>
    <div>
        <div class="ft20 cl-black cl-main ftw500">长者档案</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <a-form-item label="姓名">
                        <a-input v-model="search.name" placeholder="请输入姓名"></a-input>
                    </a-form-item>

                    <a-form-item label="手机号">
                        <a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
                    </a-form-item>

                    <a-form-item label="站点">
                        <div>
                            <a-select style="width: 180px" placeholder="全部站点" v-model="search.shop_id">
                                <a-select-option :value="0">全部站点</a-select-option>
                                <a-select-option :value="item.shop_id" :key="index"
                                                 v-for="(item,index) in shop_list">{{ item.name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-form-item>
                    <a-form-item label="建档时间">
                        <a-range-picker @change="changeTime" v-model="search.time" format="YYYY-MM-DD"
                                        valueFormat="YYYY-MM-DD"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20 pd20 bg-w">
            <div>
                <a-button style="margin-right: 10px;" icon="plus" type="primary" @click="addElderAct()">添加长者
                </a-button>
                <a-button type="primary" ghost @click="contactUs()"><i class="iconfont iconimport mr5"></i>导入长者
                </a-button>
            </div>
            <div class="wxb-table-gray mt20">
                <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                         :data-source="datas" :loading="loading">
                    <div class="flex alcenter left" slot="vip" slot-scope="record,index">
                        <img v-if="record.cover_img!=null" :src="record.cover_img" class="member-index-face"/>
                        <img v-else src="../../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face"/>
                        <div class="ml5" style="text-align: left;">
                            <div>{{ record.name }}
                                <a-badge v-if="record.gender_text == '男'" :count="record.gender_text"
                                         :number-style="{padding:'0px',backgroundColor: '#fff', color: '#2db7f5', boxShadow: '0 0 0 1px #2db7f5 inset',}"/>
                                <a-badge v-else :count="record.gender_text"
                                         :number-style="{padding:'0px',backgroundColor: '#fff', color: '#87d068', boxShadow: '0 0 0 1px #87d068 inset',}"/>
                            </div>
                            <div class="mt2">{{ record.mobile }}</div>
                        </div>
                    </div>

                    <template slot="action" slot-scope="record,index">
                        <div class="flex center">
                            <a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="edit(record)">
                                            <span>修改信息</span>
                                        </a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </template>
                </a-table>
            </div>
        </div>

        <div v-if="importFromLoading">
            <import-from :visible="importFromLoading" @cancel="cancelFrom"></import-from>
        </div>

    </div>

</template>

<script>

import {listMixin} from '@/common/mixin/list';
import WaterMark from '@/common/watermark.js'
import importFrom from './components/elder/modal/import.vue';
import moment from "moment/moment";

export default {
    mixins: [listMixin],
    components: {
        importFrom
    },
    data() {
        return {
            waterMark: null,
            importFromLoading: false,
            loading: false,
            editloading: false,
            confirmLoading: false,
            currentPage: Number(sessionStorage.getItem('adminEldercurrentPage')) || 1,
            pagination: {
                current: Number(sessionStorage.getItem('adminEldercurrentPage')) || 1, // 默认当前页数
                pageSize: 10, //每页中显示10条数据
                total: 0,
                onChange: (current) => {
                    this.currentPage = current;
                }
            },
            shop_list: [],
            search: {
                name: '',
                mobile: "",
                shop_id: 0,
                birthday: 1,
								time:[]
            },
            level: [],
            columns: [
                {title: '编号', dataIndex: 'id', width: 80, align: 'center'},
                {title: '长者姓名', key: 'vip', align: 'center', width: 220, scopedSlots: {customRender: 'vip'}},
                {title: '居住详址', dataIndex: 'home_address', width: 290, align: 'left', ellipsis: true},
                {title: '年龄', dataIndex: 'ages', align: 'right', ellipsis: true},
                {title: '出生日期', dataIndex: 'birthday', align: 'left', ellipsis: true},
                {title: '建档时间', dataIndex: 'add_time', width: 200, align: 'left', ellipsis: true},
                {title: '操作', key: 'action', align: 'center', scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
            member: {},
            member_id: 0,
        }
    },
    methods: {
        getLists() {
            if (this.$route.query && this.isPageInit) {
                if (this.$route.query.inquire === 'toDay') {
                    this.search.time = [
                        moment().format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                }
                if (this.$route.query.inquire === 'thisMonth') {
                    this.search.time = [
                        moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                }
            }
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('platform/member/getElderList', {
                name: this.search.name,
                mobile: this.search.mobile,
								time: this.search.time,
                birthday: this.search.birthday,
                shop_id: this.search.shop_id,
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                this.pagination.total = res.total;
                this.pagination.showTotal = (total) => "共" + res.total + "条";
                this.datas = res.list;
                this.shop_list = res.shop_list;
                this.level = res.level;
                this.loading = false;
            }).catch(res => {
                this.loading = false;
            })
        },
        edit(record) {
            sessionStorage.setItem('adminEldercurrentPage', this.currentPage);
            this.$router.push('/memberAdmin/editelder?id=' + record.id);
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            sessionStorage.setItem('adminEldercurrentPage', pagination.current);
            this.getLists();
        },
        addElderAct() {
            sessionStorage.setItem('adminEldercurrentPage', this.currentPage);
            this.$router.push('/memberAdmin/editelder');
        },
        contactUs() {
            this.importFromLoading = true;
        },
        cancelFrom() {
            this.importFromLoading = false;
        },

        // 设置水印
        showMark() {
            let userinfo = JSON.parse(localStorage.getItem('userinfo'));
            this.waterMark = new WaterMark({
                watermark_txt: userinfo.name, // 水印的内容
                watermark_x: 150, // 水印起始位置x轴坐标
                watermark_y: 150, // 水印起始位置Y轴坐标
                watermark_rows: 0, // 水印行数
                watermark_cols: 0, // 水印列数
                watermark_x_space: 100, // 水印x轴间隔
                watermark_y_space: 100, // 水印y轴间隔
                watermark_width: 150, // 水印宽度
                watermark_height: 80, // 水印长度
                watermark_angle: 315, // 水印倾斜度数
                monitor: false,
            });
        },
        // 隐藏水印
        hidden() {
            if (this.waterMark) {
                this.waterMark.remove()
            }
        },
        changeTime(value) {
            this.search.time = value;
        },

    },
    mounted() {
        this.showMark();
        // 监听离开隐藏水印
        window.addEventListener("beforeunload", this.hidden);
    },
    // 销毁组件
    destroyed() {
        this.hidden()
    }
}
</script>

<style>
.member-index-face {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    border: 2px solid #FFFFFF;
}

.member-index-action {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #FFFFFF;
    border: 1px solid #EBEDF5;
    text-align: center;
    line-height: 32px;
}

.watermark {
    width: 1109px !important;
    height: 4042px !important;
    max-width: unset !important;
    max-height: unset !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    z-index: 11 !important;
    pointer-events: none !important;
    background-repeat: repeat !important;
    opacity: 0.5 !important;
    display: block !important;
    visibility: visible !important;
    clip: initial !important;
    clip-path: initial !important;
    transform: initial !important;
}

</style>
